import React from "react";
import { Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, Link } from "react-router-dom";
import "./sidebar.css";
import IRouter from "./interface";
import { faHome, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";

interface ISideBar {
  menuData: IRouter[];
  panelName: string;
  baseUrl: string;
}

export default function SideBar({ menuData, panelName, baseUrl }: ISideBar) {
  let navigate = useNavigate();

  console.log()
  const showAllowedMenu = menuData.filter(
    (routes) => routes.navbarShow === true
  );

  return (
    <>
      <Nav
        defaultActiveKey="0"
        className="flex-column xcn-dashboard-sub-nav flex-sm-column bg-primary"
      >
        <div className="d-flex justify-content-center align-items-center mb-1">
          <div className="text-white fw-bold mt-4 xcn-text-12">
            <span>
              <FontAwesomeIcon
                icon={faHome}
                className="me-2 xcn-link-pointer"
              />
            </span>
            EXMBNK
          </div>
        </div>

        <hr className="text-white mx-3 mt-4" />

        {showAllowedMenu.map((data, index) => {
          if (window.location.href.split("/")[4] === data.path) {
            return (
              <div
                key={index}
                className="xcn-sidebar-links xcn-link-pointer text-primary d-flex justify-content-between align-items-center mb-3"
                onClick={() => navigate(baseUrl + "/" + data.path)}
              >
                <FontAwesomeIcon icon={data.icon} />
                <div className="ms-2">
                  {data.name}
                </div>
              </div>
            );
          }
          else {
            return (
              <div
                key={index}
                className="xcn-sidebar-links-not-selected xcn-link-pointer d-flex justify-content-between align-items-center mb-3"
                onClick={() => navigate(baseUrl + "/" + data.path)}
              >
                <FontAwesomeIcon icon={data.icon} />
                <div className="ms-2">
                  {data.name}
                </div>
              </div>
            );
          }
        })}

        <div className="xrg-nav-bottom">
          <hr className="text-white mx-3" />

          <div className="fw-bold text-center text-white" onClick={() => { 
              sessionStorage.clear(); 
              window.location.reload();
            }}>
            <FontAwesomeIcon icon={faSignOutAlt} className="text-white me-2"  />
            Logout
          </div>
        </div>
      </Nav>
    </>
  );
}
