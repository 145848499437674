import React from "react";

const CustomToggle = React.forwardRef(
    ({ children, onClick }: any, ref: any) => (
        <a
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children}
        </a>
    )
);


export default CustomToggle;