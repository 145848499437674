import { Button, Form } from "react-bootstrap";
import SideModal from "../UI/SideModal/SideModal";
import { useState } from "react";
import { AdminService } from "../../services/admin.service";
import toast from "react-hot-toast";

interface IAddCategoryModal {
    show: any
    handleClose: any
}

export default function AddCategoryModal(props: IAddCategoryModal) {
    const [data, setData] = useState<any>()

    const addExamCategory = async () => {
        await AdminService.addExamCategory(data).then((res) => {
            if (res.status === 200) {
                props.handleClose()
            }
        }).catch((err) => {
            toast.error(err.response.data)
        })
    }

    return (
        <>
            <SideModal
                show={props.show}
                handleClose={props.handleClose}
                title="Add Exam Category"
                body={
                    <>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control onChange={(e: any) => setData({ ...data, name: e.target.value })} />
                        </Form.Group>
                        <Form.Group className="d-flex align-items-center gap-2 mb-3">
                            <Form.Label>Status</Form.Label>
                            <Form.Switch onChange={(e: any) => setData({ ...data, allowed: e.target.checked })} />
                        </Form.Group>
                    </>
                }
                footer={
                    <>
                        <Button variant="primary" onClick={addExamCategory}>
                            Create
                        </Button>
                    </>
                }
            />
        </>
    )
}