import toast from "react-hot-toast";
import makeRequest from "../../api/makeRequest";
import  url  from "../../api/urls";
import {
  LOGIN_FAILURE,
  LOGIN_INITIAL,
  LOGIN_SUCCESS,
  SET_TOKEN,
} from "../constants/auth";

const onLoginInitial = (payload?: object) => ({ type: LOGIN_INITIAL, payload });
const onLoginSuccess = (payload?: object) => ({ type: LOGIN_SUCCESS, payload });
const onLoginFailure = (payload?: object) => ({ type: LOGIN_FAILURE, payload });


export const onSetToken = (payload?: object) => ({ type: SET_TOKEN, payload });

export const onLogin = (payload: { email: string; password: string }) => {
  return async (dispatch: any) => {
    try {
      dispatch(onLoginInitial(payload));

      const { data } = await makeRequest(url.login, "POST", payload);
      if (data.token) {
        sessionStorage.setItem("authKey", data.token);
      }
      dispatch(onLoginSuccess(data));
    } catch (error: any) {
      dispatch(onLoginFailure(error));
      toast.error("Invalid Email or Password.");
    }
  };
};

