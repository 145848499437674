import { Button, Form } from "react-bootstrap";
import SideModal from "../UI/SideModal/SideModal";
import { useEffect, useState } from "react";
import { AdminService } from "../../services/admin.service";
import toast from "react-hot-toast";

interface IAddCategoryModal {
    show: any
    handleClose: any,
    categoryData: any
}

export default function EditCategoryModal(props: IAddCategoryModal) {

    const [data, setData] = useState<any>()

    console.log(data)

    const editExamCategory = async () => {
        const payload = {
            name: data?.name,
            allowed: data?.allowed
        }
        await AdminService.editExamCategory(data._id, payload).then((res) => {
            if (res.status === 200) {
                props.handleClose()
            }
        }).catch((err) => {
            toast.error(err.response.data)
        })
    }

    useEffect(() => {
        setData(props.categoryData)
    }, [props.show])

    return (
        <>
            <SideModal
                show={props.show}
                handleClose={props.handleClose}
                title="Edit Exam Category"
                body={
                    <>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control defaultValue={data?.name} onChange={(e: any) => setData({ ...data, name: e.target.value })} />
                        </Form.Group>
                        <Form.Group className="d-flex align-items-center gap-2 mb-3">
                            <Form.Label>Status</Form.Label>
                            <Form.Check checked={data?.allowed} onChange={(e: any) => setData({ ...data, allowed: e.target.checked })} />
                        </Form.Group>
                    </>
                }
                footer={
                    <>
                        <Button variant="primary" onClick={editExamCategory}>
                            Save
                        </Button>
                    </>
                }
            />
        </>
    )
}