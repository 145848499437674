export const FETCH_COMPANIES_INITIAL = "FETCH_COMPANIES_INITIAL";
export const FETCH_COMPANIES_SUCCESS = "FETCH_COMPANIES_SUCCESS";
export const FETCH_COMPANIES_FAILURE = "FETCH_COMPANIES_FAILURE";

export const ADD_COMPANY_INITIAL = "ADD_COMPANY_INITIAL";
export const ADD_COMPANY_SUCCESS = "ADD_COMPANY_SUCCESS";
export const ADD_COMPANY_FAILURE = "ADD_COMPANY_FAILURE";

export const CHANGE_INPUT = "CHANGE_INPUT";

export const COMPANY_INPUT_ERROR = "COMPANY_INPUT_ERROR";

export const ON_COMPANY_INPUT_CHANGE_MODE_TOGGLE =
  "ON_COMPANY_INPUT_MODE_TOGGLE";

export const FETCH_COMPANY_INITIAL = "FETCH_COMPANY_INITIAL";
export const FETCH_COMPANY_SUCCESS = "FETCH_COMPANY_SUCCESS";
export const FETCH_COMPANY_FAILURE = "FETCH_COMPANY_FAILURE";

export const UPDATE_COMPANY_INITIAL = "UPDATE_COMPANY_INITIAL";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAILURE = "UPDATE_COMPANY_FAILURE";
