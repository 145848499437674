import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import FormInput from "../../../../Components/UI/FormInput";
import SideModal from "../../../../Components/UI/SideModal/SideModal";
import Validation from "../../../../helper/Validations";
import {
  changeInput,
  onAddCompanies,
  onCompanyInputError,
  onCompanyInputTogggle,
} from "../../../../store/actions/company";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";

const AddCompanyDialog = () => {
  const inputData = useAppSelector((state) => state.company.companyInput);
  const errors = useAppSelector((state) => state.company.companyInputErrors);
  const loader = useAppSelector((state) => state.company.addEditCompanyLoader);

  const isOpenDialog = useAppSelector(
    (state) => state.company.isCompanyOnInputMode
  );
  const dispatch = useAppDispatch();

  const onChangeValue = (e: any) => {
    dispatch(changeInput({ [e.target.name]: e.target.value }));
  };

  const { email, name, employeesNumber } = inputData;

  const inputDataCompany = {
    email: email,
    companyName: name,
    // numberOfUsers: employeesNumber,
  };

  const onSubmit = () => {
    console.log('clicked')
    const isValidEmail = Validation.isEmail(email).isValid;
    const isValidName = name === "" || !name ? false : true;
    // const isValidEmployeeNumber = +employeesNumber && employeesNumber > 1;
    if (!isValidEmail || !isValidName ) {
      dispatch(
        onCompanyInputError({
          email: !isValidEmail,
          name: !isValidName,
          // employeesNumber: !isValidEmployeeNumber,
        })
      );
    } else {
      dispatch(onAddCompanies(inputDataCompany));
    }
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={() => dispatch(onCompanyInputTogggle())}
        size="sm"
        className="xcn-button"
      >
        Add Company
      </Button>


      <SideModal
        show={isOpenDialog}
        handleClose={() => dispatch(onCompanyInputTogggle())}
        title="Add Company"
        body={
          <>
            <Form>
              <FormInput
                classes="mb-3"
                onChange={(e: HTMLElement) => onChangeValue(e)}
                defaultValue={name}
                identifier="name"
                error={errors.name}
                label="Company Name"
                errMessage="Invalid Name"
                readonly={loader}
              />
              <FormInput
                classes="mb-3"
                onChange={(e: HTMLElement) => onChangeValue(e)}
                defaultValue={email}
                identifier="email"
                error={errors.email}
                label="Company Email"
                errMessage="Invalid Email"
                readonly={loader}
              />
              {/* <FormInput
                classes="mb-3"
                onChange={(e: HTMLElement) => onChangeValue(e)}
                defaultValue={employeesNumber}
                identifier="employeesNumber"
                error={errors.employeesNumber}
                label="Total Employees"
                errMessage="Must be greater than 0"
                readonly={loader}
                type="number"
              /> */}
            </Form>

          </>
        }
        footer={
          <>
            <Button variant="primary" onClick={onSubmit}>
              Create
            </Button>
          </>
        }
      />
    </>
  );
};

export default AddCompanyDialog;
