import axios, { Method } from "axios";
import axiosHandler from "./../helper/axioHandler";

export interface IParams {
  value: any;
  index: string;
}

export default async function makeRequest(
  url: string,
  method: Method,
  inputPayload?: any
) {
  let requestConfig = {
    baseURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_VER}`,
    url: url,
    method: method,
    headers: {
      Authorization: sessionStorage.getItem("authKey")  || "",
    },
    data: {},
  };

  console.log(requestConfig, "requestConfig")

  if (method !== "get" && inputPayload) {
    requestConfig.data = inputPayload;
  }

  try {
    let response = await axios.request(requestConfig);
    return response;
  } catch (error: any) {
    console.log("makeRequest()", error);
    axiosHandler(error);
    throw error;
  }
}
