import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { AdminService } from '../../services/admin.service';
import toast from 'react-hot-toast';

interface IUpdateSubject {
    show: any,
    handleClose: any,
    data: any
}

export default function UpdateSubjectModal(props: IUpdateSubject) {

    const [subjectData, setSubjectData] = useState<any>();

    const updateSubjectData = async () => {
        await AdminService.updateSubject(subjectData._id, { name: subjectData.name }).then((res) => {
            if (res.status === 200) {
                toast.success("Subject name updated")
                props.handleClose();
            }
        }).catch(err => {
            toast.error(err.response.data)
        })
    }


    useEffect(() => {
        setSubjectData(props.data)
    }, [props.show])

    return (
        <Modal
            show={props.show}
            onHide={props.handleClose}
            backdrop="static"
            animation={true}
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Edit Subject</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label className="form-label">Subject Name</Form.Label>
                    <Form.Control className="form-control" defaultValue={subjectData?.name} onChange={(e: any) => setSubjectData({ ...subjectData, name: e.target.value })} />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={updateSubjectData}>
                    Save Changes
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
