import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  FloatingLabel,
  Form,
  Button,
} from "react-bootstrap";

import { useLocation, useNavigate } from "react-router-dom";
import Validation from "../../../helper/Validations";
import { onLogin } from "../../../store/actions/auth";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";

const Login = () => {
  const isLoggedIn = useAppSelector((state) => state?.auth?.loggedIn);
  const loader = useAppSelector((state) => state?.auth?.loading);
  const dispatch = useAppDispatch();
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    email: false,
    password: false,
  });

  const { state }: any = useLocation();
  const navigate = useNavigate();
  const redirect = state?.redirectTo;
  useEffect(() => {
    if (isLoggedIn) {
      if (!redirect) {
        navigate("/superadmin/companies", { replace: true });
      } else {
        // state clear
        window.history.replaceState({}, "");
        // navigate to redirect
        navigate(redirect, { replace: true });
      }
    }
  }, [isLoggedIn, navigate, redirect]);

  const loginSubmit = () => {
    const emailValidation = Validation.isEmail(email);
    const isValid = password === "" || !password ? false : true;
    if (!emailValidation.isValid || !isValid) {
      setErrors({
        email: !emailValidation.isValid,
        password: !isValid,
      });
    } else {
      setErrors({
        email: false,
        password: false,
      });
      dispatch(onLogin(loginData));
    }
  };

  const { email, password } = loginData;

  const onChangeValue = (e: any) => {
    setLoginData({ ...loginData, [e.target.name]: e.target.value });
  };

  return (
    !isLoggedIn && (
      <>
        <Container>
          <h1 className="text-center font-weight-bold xcn-login-header">
            Admin Login
          </h1>
          <Row className="justify-content-md-center  mt-4">
            <Col xs lg="2" />
            <Col md="auto">
              <Card
                className="p-3"
                style={{ maxWidth: "25rem", minWidth: "25vw" }}
              >
                <>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Email address"
                    className={"mb-3"}
                  >
                    <Form.Control
                      type="email"
                      placeholder="name@example.com"
                      name="email"
                      value={email}
                      isInvalid={errors?.email}
                      onChange={(e) => onChangeValue(e)}
                    />
                    {errors.email && (
                      <Form.Control.Feedback type="invalid">
                        invalid
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                  <FloatingLabel controlId="floatingPassword" label="Password">
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      name="password"
                      value={password}
                      isInvalid={errors.password}
                      onChange={(e) => onChangeValue(e)}
                    />
                    {errors.password && (
                      <Form.Control.Feedback type="invalid">
                        Please choose a username.
                      </Form.Control.Feedback>
                    )}
                  </FloatingLabel>
                </>
                <Button
                  variant="primary"
                  type="submit"
                  className="mt-4"
                  size="lg"
                  onClick={loginSubmit}
                  disabled={loader}
                >
                  <span className="xcn-text-14">Login</span>
                </Button>
              </Card>
            </Col>
            <Col xs lg="2" />
          </Row>
        </Container>
      </>
    )
  );
};

export default Login;
