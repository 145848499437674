import { faEllipsisVertical, faGears, faLanguage, faRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Button, Card, Form, FormCheck, Table, FloatingLabel, Dropdown, Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { AdminService } from '../../../services/admin.service';
import { useParams } from 'react-router-dom';
import LanguageModal from '../../../Components/Modal/Language.modal';
import BasicModal from '../../../Components/Modal/Basic.modal';
import { UserRoleEnum } from '../../../enum/userRole.enum';
import CustomToggle from '../../../Components/toggle';


export default function CompanyUsersIndex() {

    const [users, setUsers] = useState<any[]>();
    const [userCount, setUserCount] = useState<number>(0);

    const [updatePasswordModal, setUpdatePasswordModal] = useState<boolean>(false);
    const [createUserModal, setcreateUserModal] = useState<boolean>(false);
    const [updatedPassword, setUpdatedPassword] = useState<any>();
    const [updatedAddUser, setUpdatedAddUser] = useState<any>();
    const [showLanguageModal, setShowLanguageModal] = useState<any>(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    const { companyId }: any = useParams();

    const getAllUsers = async () => {
        setLoading(true);
        await AdminService.getAllUsers(companyId).then(response => {
            if (response.status === 200) {
                setUsers(response.data.users)
                setUserCount(response.data.totalUsers)
            }
        })
        .catch(e => {
            console.error(e)
        })
        .finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        getAllUsers();
    }, []);

    const checkIfRoleAllowed = (roles: any[], checkRole: any) => {
        const exist = roles.find((data: any) => data.role == checkRole && data.allowed === true)
        return exist ? true : false;
    }

    const checkTranslatorIsAllowed = (roles: any[], checkRole: any) => {
        const exist = roles.find((data: any) => data.role == checkRole && data.allowed === true)
        return exist ? true : false;
    }

    const handleRoleChange = async (userId: string, selectedRole: any, checked: any) => {
        await AdminService.changeUserRole(userId, selectedRole, checked).then(res => {
            if (res.status === 200) {
                getAllUsers();
                toast.success('Role Updated')
            }
        }).catch(e => {
            console.error(e)
            toast.error("Something went wrong while updating roles")
        })
    }

    const handleBlockUnblock = async (userId: string, checked: boolean) => {
        await AdminService.blockUnblockUser(userId, checked).then(res => {
            if (res.status === 200) {
                getAllUsers();
                toast.success("Updated")
            }
        })
    }

    const handleUpdatePassword = async () => {
        await AdminService.userUpdate(updatedPassword._id, updatedPassword.password).then(res => {
            if (res.status === 200) {
                toast.success("Password Updated Successfully")
                setUpdatePasswordModal(false)
            }
        }).catch(e => {
            console.error(e)
            toast.error(e.response.data);
        })
    }

    const handleUserAdd = async () => {
        await AdminService.register(companyId, updatedAddUser.username, updatedAddUser.password).then(res => {
            if (res.status === 200) {
                toast.success("User Added Successfully")
                getAllUsers();
                setcreateUserModal(false)

            }
        }).catch(e => {
            console.error(e);
            toast.error(e.response.data);
        })
    }

    return (
        <>


            <LanguageModal show={showLanguageModal} handleClose={setShowLanguageModal} reload={getAllUsers} />

            <BasicModal
                show={updatePasswordModal}
                setShow={setUpdatePasswordModal}
                heading="Update Password"
                body={<>
                    <h6>New Password</h6>
                    <Form.Group>
                        <Form.Control type="text" onChange={(e: any) => setUpdatedPassword({ ...updatedPassword, password: e.target.value })} />
                    </Form.Group>
                    <Button variant="primary" className='mt-2' size="sm" onClick={handleUpdatePassword}> Update Password </Button>
                </>}
            />


            <BasicModal
                show={createUserModal}
                setShow={setcreateUserModal}
                heading={<b>Add User</b>}
                body={<>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Email Address"
                        className="mb-3"
                    >
                        <Form.Control type="email" onChange={(e: any) => setUpdatedAddUser({ ...updatedAddUser, username: e.target.value })} />
                    </FloatingLabel>
                    <FloatingLabel
                        controlId="floatingInput"
                        label="Password"
                        className="mb-3">
                        <Form.Control type="password" onChange={(e: any) => setUpdatedAddUser({ ...updatedAddUser, password: e.target.value })} />
                    </FloatingLabel>
                    <Button variant="primary" className='mt-2' size="sm" onClick={handleUserAdd}> Add User </Button>
                </>
                }
            />

            <div className="d-flex justify-content-between align-items-center mt-3">
                <h4>
                    Users ({userCount})
                </h4>
                <Button variant="primary" size="sm" onClick={() => setcreateUserModal(true)}>
                    Add User
                </Button>
            </div>

            {
                loading ?
                    <Skeleton count={5} height={60} />
            :
            <div className="mt-2">
                <Card className="reg-card bg-white p-3">
                    <Table hover responsive className="p-4 mb-5">
                        <thead>
                            <tr className='bg-light'>
                                <th>
                                    Name
                                </th>
                                <th>
                                    Email
                                </th>
                                <th colSpan={Object.keys(UserRoleEnum).length}>
                                    Roles
                                </th>
                                <th>
                                    Active
                                </th>
                                <th>
                                    Actions
                                </th>
                            </tr>
                            <tr className="text-secondary xcn-text-10">
                                <th></th>
                                <th></th>
                                {Object.keys(UserRoleEnum).map((data) => {
                                    return (
                                        <th className="text-center">
                                            {data}
                                        </th>
                                    )
                                })}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {users && users.length > 0 && users.map((data: any, index: number) => {
                                return (
                                    <tr>
                                        <td>
                                            {data.name}
                                        </td>
                                        <td>
                                            {data.username}
                                        </td>
                                        {Object.values(UserRoleEnum).map((chkrole: string) => {
                                            return (
                                                <td className='text-center'>
                                                    <FormCheck
                                                        checked={checkIfRoleAllowed(data.role, chkrole)}
                                                        onChange={(e: any) => handleRoleChange(data._id, chkrole, e.target.checked)}
                                                    />
                                                </td>
                                            )
                                        })}
                                        <td className='text-center'>
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked={data.active}
                                                onChange={(e: any) => handleBlockUnblock(data._id, e.target.checked)}
                                            />
                                        </td>
                                        <td>
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    as={CustomToggle}
                                                    id="dropdown-custom-components"
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faEllipsisVertical}
                                                    />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => {
                                                        setUpdatedPassword({ _id: data._id })
                                                        setUpdatePasswordModal(true)
                                                    }}>
                                                        <FontAwesomeIcon
                                                            icon={faGears}
                                                            className="text-info xcn-link-pointer"
                                                        />
                                                        <span className="text-secondary fs-12 ms-2">
                                                            Change Password
                                                        </span>
                                                    </Dropdown.Item>
                                                    {checkTranslatorIsAllowed(data.role, "translator") && <Dropdown.Item onClick={() => setShowLanguageModal(data?._id)}>
                                                        <FontAwesomeIcon
                                                            icon={faLanguage}
                                                            className="text-info xcn-link-pointer"
                                                        />
                                                        <span className="text-secondary fs-12 ms-2">
                                                            Add Languages
                                                        </span>
                                                    </Dropdown.Item>}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </Card>
            </div>
            }
        </>
    )
}