import * as actionTypes from "../../constants/company";
import { IAction } from "../interface";
import { ICompanyState } from "./interface";

const initialState: ICompanyState = {
  loader: false,
  companies: [],
  companyInput: {},
  companyInputErrors: {},
  isCompanyOnInputMode: false,
  addEditCompanyLoader: false,
  activeCompany: {},
  editOpen: false,
};

const companyReducer = (state = initialState, action: IAction) => {
  if (action.type === actionTypes.FETCH_COMPANIES_INITIAL) {
    return { ...state, loader: true, companies: [] };
  }
  if (action.type === actionTypes.FETCH_COMPANIES_SUCCESS) {
    return { ...state, loader: false, companies: action.payload || [] };
  }
  if (action.type === actionTypes.FETCH_COMPANIES_FAILURE) {
    return { ...state, loader: false, companies: [] };
  }
  if (action.type === actionTypes.ADD_COMPANY_INITIAL) {
    return {
      ...state,
      addEditCompanyLoader: true,
      companyInputErrors: {},
    };
  }
  if (action.type === actionTypes.ADD_COMPANY_SUCCESS) {
    return {
      ...state,
      addEditCompanyLoader: false,
      companyInput: action.payload || {},
      companyInputErrors: {},
      isCompanyOnInputMode: false,
    };
  }
  if (action.type === actionTypes.ADD_COMPANY_FAILURE) {
    return {
      ...state,
      addEditCompanyLoader: false,
    };
  }
  if (action.type === actionTypes.CHANGE_INPUT) {
    return {
      ...state,
      companyInput: { ...state.companyInput, ...action.payload },
    };
  }

  if (action.type === actionTypes.COMPANY_INPUT_ERROR) {
    return {
      ...state,
      companyInputErrors: { ...state.companyInputErrors, ...action.payload },
    };
  }

  if (action.type === actionTypes.ON_COMPANY_INPUT_CHANGE_MODE_TOGGLE) {
    return {
      ...state,
      isCompanyOnInputMode: !state.isCompanyOnInputMode,
      companyInput: action?.payload?.isEdit ? { ...state.activeCompany } : {},
    };
  }

  if (action.type === actionTypes.FETCH_COMPANY_INITIAL) {
    return {
      ...state,
      loader: true,
      activeCompany: {},
    };
  }

  if (action.type === actionTypes.FETCH_COMPANY_SUCCESS) {
    return {
      ...state,
      loader: false,
      activeCompany: action.payload?.user || [],
    };
  }

  if (action.type === actionTypes.FETCH_COMPANY_FAILURE) {
    return {
      ...state,
      loader: false,
      activeCompany: {},
    };
  }

  return state;
};

export default companyReducer;
