import makeRequest from "../../api/makeRequest";
import url from "../../api/urls";
import {
  ADD_COMPANY_FAILURE,
  ADD_COMPANY_INITIAL,
  ADD_COMPANY_SUCCESS,
  CHANGE_INPUT,
  COMPANY_INPUT_ERROR,
  FETCH_COMPANIES_FAILURE,
  FETCH_COMPANIES_INITIAL,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANY_FAILURE,
  FETCH_COMPANY_INITIAL,
  FETCH_COMPANY_SUCCESS,
  ON_COMPANY_INPUT_CHANGE_MODE_TOGGLE,
} from "../constants/company";

import toast from "react-hot-toast";
import { RequstMethods } from "../../api/requestMethode";

const onFetchCompaniesInitial = (payload?: object) => ({
  type: FETCH_COMPANIES_INITIAL,
  payload,
});
const onFetchCompaniesSuccess = (payload?: object) => ({
  type: FETCH_COMPANIES_SUCCESS,
  payload,
});
const onFetchCompaniesFailure = (payload?: object) => ({
  type: FETCH_COMPANIES_FAILURE,
  payload,
});

const onAddNewCompanyInitial = (payload?: object) => ({
  type: ADD_COMPANY_INITIAL,
  payload,
});
const onAddNewCompanySuccess = (payload?: object) => ({
  type: ADD_COMPANY_SUCCESS,
  payload,
});
const onAddNewCompanyFailure = (payload?: object) => ({
  type: ADD_COMPANY_FAILURE,
  payload,
});

const onFetchCompanyInitial = (payload?: object) => ({
  type: FETCH_COMPANY_INITIAL,
  payload,
});
const onFetchCompanySuccess = (payload?: object) => ({
  type: FETCH_COMPANY_SUCCESS,
  payload,
});

const onFetchCompanyFailure = (payload?: object) => ({
  type: FETCH_COMPANY_FAILURE,
  payload,
});

export function changeInput(payload: object) {
  return {
    type: CHANGE_INPUT,
    payload,
  };
}

export const onCompanyInputError = (payload: object) => {
  return {
    type: COMPANY_INPUT_ERROR,
    payload,
  };
};

export const onFetchCompanies = (payload?: object) => {
  return async (dispatch: any) => {
    try {
      dispatch(onFetchCompaniesInitial(payload));

      const { data } = await makeRequest(
        url.company,
        RequstMethods.GET,
        payload
      );
      dispatch(onFetchCompaniesSuccess(data.finalData));
    } catch (error: any) {
      dispatch(onFetchCompaniesFailure(error));
      toast.error(error.message || "Failed to fetch Companies.");
    }
  };
};

export const onAddCompanies = (payload: object) => {
  return async (dispatch: any) => {
    try {
      dispatch(onAddNewCompanyInitial(payload));
      const { data } = await makeRequest(
        url.addCompanies,
        RequstMethods.POST,
        payload
      );
      dispatch(onAddNewCompanySuccess(data));
      dispatch(onFetchCompanies());
    } catch (error: any) {
      dispatch(onAddNewCompanyFailure({ message: error.message }));
      toast.error("Invalid Fields.");
    }
  };
};

export const onCompanyInputTogggle = (payload?: object) => {
  return {
    type: ON_COMPANY_INPUT_CHANGE_MODE_TOGGLE,
    payload,
  };
};

export const onFetchCompany = (payload?: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(onFetchCompanyInitial(payload));

      const data = await makeRequest(
        `${url.viewCompanies}${payload.id}`,
        RequstMethods.GET,
        payload
      );
      dispatch(onFetchCompanySuccess(data.data));
    } catch (error: any) {
      dispatch(onFetchCompanyFailure(error));
      toast.error(error.message || "Failed to fetch Companies.");
    }
  };
};
