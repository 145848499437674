import { faBook, faBuilding, faChartLine, faList } from "@fortawesome/free-solid-svg-icons";
import Companies from "../views/private/Companies";
import ViewCompanies from "../views/private/Companies/ViewCompany";
import StatsIndex from "../views/private/Stats/Stats.index";
import SubjectIndex from "../views/private/Subjects/Subject.index";
import CompanyIndex from "../views/private/ViewCompany/company.index";
import Login from "../views/public/Login";
import IRouterPrivate from "./interfacePrivate";
import IRouterPublic from "./interfacePublic";
import SubjectById from "../views/private/Subjects/SubjectById";
import ExamCategory from "../views/private/ExamCategory/ExamCategory";
import CompanyUsersIndex from "../views/private/ViewCompany/CompanyUsers.index";

export const baseUrl = "/superAdmin";

export const publicConfig: IRouterPublic[] = [
  {
    path: "/",
    element: Login,
  },
  {
    path: "/superAdmin/login",
    element: Login,
  },
];

export const privateConfig: IRouterPrivate[] = [
  {
    path: "companies",
    element: Companies,
    navbarShow: true,
    name: "Companies",
    icon: faBuilding,
  },

  {
    path: "companies/:id",
    element: ViewCompanies,
    navbarShow: false,
    name: "",
    icon: faBuilding,
  },

  {
    path: "subject/:id",
    element: SubjectById,
    navbarShow: false,
    name: "",
    icon: faBuilding,
  },

  {
    path: "view-company/:companyId",
    element: CompanyUsersIndex,
    navbarShow: false,
    name: "",
    icon: faBuilding,
  },

  {
    path: "subject",
    element: SubjectIndex,
    navbarShow: true,
    name: "Subjects",
    icon: faBook,
  },

  {
    path: "exam-category",
    element: ExamCategory,
    navbarShow: true,
    name: "Exam Category",
    icon: faList,
  },

  {
    path: "statistics",
    element: StatsIndex,
    navbarShow: true,
    name: "Statistics",
    icon: faChartLine,
  },

];
