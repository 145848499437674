import React, { useEffect, useState } from "react";
import { AdminService } from "../../../../services/admin.service";
import { onFetchCompanies } from "../../../../store/actions/company";
import { useAppDispatch } from "../../../../store/hooks";
import EditComapnyModal from "./EditCompanyModal";
import UpdateCompanyModal from "./ViewCompanyModal";
import classes from "./index.module.css";
import toast from "react-hot-toast";


interface IViewCompany {
  id: string
  setToggle: any
  toggle: boolean
}

const ViewCompanies = (props: IViewCompany) => {
  const [company, setCompany] = useState<any>({});


  const dispatch = useAppDispatch();

  const close = () => {
    props.setToggle(false);
  };

  // const { id } = useParams();
  const id = props.id;
  const onFetchCompany = async () => {
    await AdminService.viewCompanies({ id })
      .then((res: any) => {
        if (res.status === 200) {
          const result = res?.data?.user;
          setCompany(result)
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  console.log(company, "company")

  const onFetchUpdatedCompany = async () => {

    delete company.createdAt;
    delete company.email;
    delete company.updatedAt;
    delete company._id;
    delete company.userRole;
    delete company.companyName

    const data = { ...company, numberOfUsers: Number(company.numberOfUsers), numberOfAllowedQuestions: Number(company.numberOfAllowedQuestions) };

    await AdminService.updateCompanies({ id, data })
      .then((res: any) => {
        if (res?.status === 200) {
          toast.success("Updated")
          onFetchCompany();
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  };

  useEffect(() => {
    onFetchCompany();
  }, []);

  const onCheck = (e: any) => {
    setCompany({
      ...company,
      blocked: e.target.checked,
    });
  };
  const onChangeValue = (e: any) => {
    setCompany({ ...company, [e.target.name]: e.target.value });
  };

  const onSubmit = () => {
    onFetchUpdatedCompany();
    dispatch(onFetchCompanies());
    props.setToggle(false);
  };

  const onCancel = () => {
    props.setToggle(false);
  };

  return (
    <>
      {
        <EditComapnyModal
          show={props.toggle}
          onChangeValue={onChangeValue}
          company={company}
          close={close}
          editCompanyClass={classes.checkStatus}
          companyInputButtonsClass={classes.companyInputButtons}
          onCheck={onCheck}
          onSubmit={onSubmit}
          onCancel={onCancel}
        />
      }
    </>
  );
};

export default ViewCompanies;
