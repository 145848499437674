import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react"
import { Button, Col, Form, Row } from "react-bootstrap";
import toast from "react-hot-toast";
import { AdminService } from "../../services/admin.service"
import SideModal from "../UI/SideModal/SideModal";

interface IAddSubject {
    setShow: any
    show: boolean
}

export default function AddSubject(props: IAddSubject) {

    const [subject, setSubject] = useState<any>();


    const saveSubject = async () => {
        await AdminService.addSubject(subject).then(res => {
            if (res.status === 200) {
                toast.success("Saved")
                props.setShow(false)
            }
        }).catch(e=>{
            console.error(e)
            toast.error(e.response.data)
        });
    }
    return (
        <>
            <SideModal
                show={props.show}
                handleClose={() => props.setShow(false)}
                body={<>
                    <Form.Label className="xcn-text-10">Subject Name</Form.Label>
                    <Form.Control
                        type="text"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                    />
                </>
                }

                title="Add Subject"
                footer={<>
                    <Button onClick={saveSubject}> Add Subject </Button>
                </>}
            />
        </>
    )
}