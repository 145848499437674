import React from "react";
import MainRoutes from "./routes";
import { Toaster } from "react-hot-toast";
import "./App.css";

const App: React.FC = () => {
  return (
    <div className="App">
      <MainRoutes />
      <Toaster />
    </div>
  );
};

export default App;
