import { IAuthState } from "./interace";
import * as actionTypes from "../../constants/auth";
import { IAction } from "../interface";
import { loginFailure, loginInitial, loginSuccess, onSetToken } from "./helper";
// Define the initial state using that type
const initialState: IAuthState = {
  loading: false,
  loggedIn: false,
  token: null,
};

export const authReducer = (state = initialState, action: IAction) => {
  if (action.type === actionTypes.LOGIN_INITIAL) {
    return loginInitial(state, action);
  }
  if (action.type === actionTypes.LOGIN_SUCCESS) {
    return loginSuccess(state, action);
  }
  if (action.type === actionTypes.LOGIN_FAILURE) {
    return loginFailure(state, action);
  }
  if (action.type === actionTypes.SET_TOKEN) {
    return onSetToken(state, action);
  }

  return state;
};
