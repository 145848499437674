import makeRequest from "../api/makeRequest";
import urls from "../api/urls";
import { MethodsEnum } from "../enum/methods.enum";

export class AdminService {
  static async viewCompanies(payload: any) {
    return await makeRequest(
      `${urls.viewCompanies}${payload?.id}`,
      MethodsEnum.GET
    );
  }

  static async updateCompanies(payload: any) {
    await makeRequest(
      `${urls.editCompany}${payload?.id}`,
      MethodsEnum.PUT,
      payload?.data
    );
    return;
  }

  static async changePassword(userId: string) {
    return await makeRequest(urls.changePasswordById + "/" + userId, MethodsEnum.PUT);
  }
  static async changeComapanyPasswordById(userId: string) {
    return await makeRequest(urls.changeComapanyPasswordById + "/" + userId, MethodsEnum.PUT);
  }
  static async toggleBlockUnBlock(userId: string) {
    return await makeRequest(urls.toggleBlockUnblock + "/" + userId, MethodsEnum.GET)
  }

  static async getAllSubjects(searchText: any, pageNumber: number, perPage: number) {
    return await makeRequest(urls.subjects.get + "?searchText=" + searchText + "&pageNumber=" + pageNumber + "&perPage=" + perPage, MethodsEnum.GET);
  }


  static async updateSubject(subjectId: string, payload: any) {
    return await makeRequest(urls.subjects.updateSubject + "/" + subjectId, MethodsEnum.PUT, payload);
  }

  static async getSubjectById(subjectId: string) {
    return await makeRequest(urls.subjects.getSubjectById + "/" + subjectId, MethodsEnum.GET);
  }

  static async addSubject(subject_name: string) {
    return await makeRequest(urls.subjects.addSubject, MethodsEnum.POST, { name: subject_name });
  }

  static async getAllUsersOfCompany(companyId: string) {
    return await makeRequest(urls.getAllUsersOfCompany + "/" + companyId, MethodsEnum.GET);
  }

  static async getStats() {
    return await makeRequest(urls.getStats, MethodsEnum.GET);
  }

  static async getTopics(topicId: any) {
    return await makeRequest(urls.subjects.getTopics + "/" + topicId, MethodsEnum.GET);
  }

  static async insertTopic(topicId: any, payload: any) {
    return await makeRequest(urls.subjects.insertTopic + "/" + topicId, MethodsEnum.POST, payload);
  }

  static async updateTopic(topicId: any, payload: any) {
    return await makeRequest(urls.subjects.updateTopic + "/" + topicId, MethodsEnum.PUT, payload);
  }

  static async getAllCompany(searchText: any) {
    return await makeRequest(urls.company + "?searchText=" + searchText, MethodsEnum.GET);
  }

  static async getAllExamCategories() {
    return await makeRequest(urls.getAllExamCategories, MethodsEnum.GET)
  }

  static async addExamCategory(payload: any) {
    return await makeRequest(urls.addExamCategory, MethodsEnum.POST, payload)
  }

  static async editExamCategory(categoryId: any, payload: any) {
    return await makeRequest(urls.editExamCategory + "/" + categoryId, MethodsEnum.PUT, payload)
  }

  static async deleteExamCategory(categoryId: any) {
    return await makeRequest(urls.deleteExamCategory + "/" + categoryId, MethodsEnum.DELETE)
  }

  static async changeUserRole(userId: any, role: any, status: any) {
    return await makeRequest(urls.changeUserRole, "put", {
        userId,
        role,
        status
    })
}

  static async blockUnblockUser(_id: any, active: any) {
      return makeRequest(urls.blockUnblockUser, "put", {
          _id, active
      })
  }

  static async userUpdate(_id: any, password: string) {
      return makeRequest(urls.userUpdate, "put", { _id, password })
  }

  static async register(companyId: any, username: string, password: string) {
      return makeRequest(urls.register+"?companyId="+companyId, "post", { username, password })
  }

  static async addLanguage(id: any, payload: any) {
      return makeRequest(urls.addLanguage + "/" + id, "put", payload)
  }

  static async getTranslatedLanguage(id: any) {
      return makeRequest(urls.getTranslatedLanguage + "/" + id, "get")
  }

  static async getAllUsers(companyId: any) {
    return makeRequest(urls.getAllCompanyUsers+"?companyId="+companyId, "get");
  }

  static async getLanguage() {
    return makeRequest(urls.getLanguage, "get");
  }

}

