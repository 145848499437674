import { Spinner } from "react-bootstrap";

const Loader = () => (
  <div style={{ display: "grid", placeItems: "center" }}>
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  </div>
);

export default Loader;
