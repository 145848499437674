import React from "react";
import { Button, Form } from "react-bootstrap";
import FormInput from "../../../../../Components/UI/FormInput";
import SideModal from "../../../../../Components/UI/SideModal/SideModal";

interface IEditCompany {
  show: boolean;
  onChangeValue: any;
  company: any;
  close: any;
  editCompanyClass?: any;
  companyInputButtonsClass: any;
  onCheck: any;
  onSubmit: any;
  onCancel: any;
}

const EditComapnyModal = ({
  show,
  onChangeValue,
  company,
  editCompanyClass,
  close,
  companyInputButtonsClass,
  onCheck,
  onSubmit,
  onCancel,
}: IEditCompany) => {
  console.log(company)
  return (
    <SideModal
      show={show}
      handleClose={close}
      title="Update Company Details"
      body={
        <>
          <Form>
            <FormInput
              classes="mb-3"
              onChange={(e: any) => onChangeValue(e)}
              value={company?.companyName}
              identifier="name"
              label="Company Name"
              readonly={true}
              disabled={true}
              type="text"
            />
            <FormInput
              classes="mb-3"
              onChange={(e: any) => onChangeValue(e)}
              value={company?.email}
              identifier="email"
              label="Company Email"
              type="email"
              readonly={true}
              disabled={true}
            />
            <FormInput
              classes="mb-3"
              onChange={(e: any) => onChangeValue(e)}
              value={company?.numberOfUsers}
              identifier="numberOfUsers"
              label="Total Employees"
              type="number"
            />

            <FormInput
              classes="mb-3"
              onChange={(e: any) => onChangeValue(e)}
              value={company?.numberOfAllowedQuestions}
              identifier="numberOfAllowedQuestions"
              label="Total Questions"
              type="number"
            />
            <div className={editCompanyClass}>
              <Form.Label className="xcn-input-label text-secondary">
                Status
              </Form.Label>
              <input
                className="mb-3"
                type="checkbox"
                onChange={onCheck}
                checked={company?.blocked}
                placeholder="Status"
                style={{ marginLeft: "15rem" }}
              />
            </div>
          </Form>
        </>
      }
      footer={
        <div className={companyInputButtonsClass}>
          <Button onClick={onSubmit}>Submit</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </div>
      }
    />
  );
};

export default EditComapnyModal;
