import React, { useEffect, useState } from "react";
import { Card, Col, Dropdown, Form, Row, Table } from "react-bootstrap";
import Loader from "../../../Components/UI/Loader";
import { onFetchCompanies } from "../../../store/actions/company";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import "./index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faEllipsisVertical, faEye } from "@fortawesome/free-solid-svg-icons";
import ViewCompanies from "./ViewCompany";
import { AdminService } from "../../../services/admin.service";
import toast from "react-hot-toast";
import PasswordModal from "../../../Components/Modal/Password.modal";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../../routes/config";
import AddCompanyDialog from "./AddCompanyDialog";


const Companies = () => {

  const navigate = useNavigate();

  const companies = useAppSelector((state) => state.company.companies);
  const loader = useAppSelector((state) => state.company.loader);
  const dispatch = useAppDispatch();

  const [companyModal, setCompanyModal] = useState("");
  const [updatedPassword, setUpdatedPassword] = useState<any>(undefined);
  const [showSectionModal, setShowSectionModal] = useState<any>(false)

  const [toggle, setToggle] = useState(false);
  useEffect(() => {
    dispatch(onFetchCompanies());
  }, []);

  const CustomToggle = React.forwardRef(
    ({ children, onClick }: any, ref: any) => (
      <a
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </a>
    )
  );

  const handleChangePassword = async (userId: string) => {
    await AdminService.changeComapanyPasswordById(userId).then(res => {
      if (res.status === 200) {
        toast.success("Password reset successfully")
      }
    }).catch(e => {
      toast.error("Unable to change password! Something went Wrong")
    })
  }

  const toggleBlockUnBlock = async (userId: string) => {
    await AdminService.toggleBlockUnBlock(userId).then((res) => {
      if (res.status === 200) {
        toast.success("Updated Status");
      }
    }).catch((e) => {
      toast.error("Unable to change! Something went Wrong")
    })
  }

  return (
    <>
      {loader && <Loader />}{" "}
      {/* {<PasswordModal
        setShow={() => setUpdatedPassword(undefined)}
        show={updatedPassword ? true : false}
        body={updatedPassword}
      />} */}
      {companies && (
        <>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5 className="header">Companies ({companies?.length})</h5>
            <Form.Group>
              <input
                type="text"
                className="xcn-search-bar"
                placeholder="type text to search"
              />
            </Form.Group>
          </div>

          <div className="d-flex justify-content-between align-items-center">
            <div className="text-secondary fw-bold xcn-text-12">
              All Companies
            </div>
            <AddCompanyDialog />
            {toggle && (
              <ViewCompanies id={companyModal} setToggle={setToggle} toggle={toggle} />
            )}
          </div>
          <div className="mt-2">
            <Card className="reg-card bg-white">
              {companies?.map((data: any, index: number) => {
                return (
                  <Row className="xcn-table-row mt-3 text-secondary align-items-center">
                    <Col md={1}>{index + 1}</Col>
                    <Col md={3}>
                      Email:{" "}
                      <span className="text-info text-decoration-underline">
                        {data.company.email || "NA"}
                      </span>
                      {data.company.blocked === true ?
                        <FontAwesomeIcon
                          icon={faCircle}
                          className="text-danger"
                          style={{ fontSize: "8px", marginLeft: "10px" }}
                        /> : <FontAwesomeIcon
                          icon={faCircle}
                          className="text-success"
                          style={{ fontSize: "8px", marginLeft: "10px" }}
                        />}
                    </Col>
                    <Col md={2}>
                      Reg. Name:{" "}
                      <span className="fw-normal text-primary" onClick={() => navigate(baseUrl + "/" + "view-company" + "/" + data.company._id)} style={{ cursor: "pointer" }}>
                        {" "}
                        {data.company.companyName || "NA"}{" "}
                        <FontAwesomeIcon icon={faEye} onClick={() => navigate(baseUrl + "/" + "view-company" + "/" + data.company._id)} style={{ cursor: "pointer" }} />
                      </span>
                    </Col>
                    <Col md={2}>
                      Users: <span className="text-danger fw-bold">{data.companyUserCount}</span>
                      <span className="fw-bold text-primary">
                        /{data.company.numberOfUsers || "0"}
                      </span>
                    </Col>
                    <Col md={2}>
                      Questions:{" "}
                      <span className="text-danger fw-bold">{data.questionCount}</span>
                      <span className="fw-bold text-primary">
                        /{data.company.numberOfAllowedQuestions || "0"}
                      </span>
                    </Col>
                    <Col md={2} className="d-flex justify-content-end fs-5">
                      <Dropdown>
                        <Dropdown.Toggle
                          as={CustomToggle}
                          id="dropxdown-custom-components"
                        >
                          <FontAwesomeIcon
                            icon={faEllipsisVertical}
                            className="text-primary xcn-link-pointer"
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              setCompanyModal(data?.company._id);
                              setToggle(!toggle);
                            }}
                          >
                            Edit
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={() => {
                              handleChangePassword(data?.company._id);
                            }}
                          >
                            Reset Password
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={() => {
                              toggleBlockUnBlock(data?.company._id);
                            }}
                          >
                            Block/Unblock
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => navigate(baseUrl + "/" + "view-company" + "/" + data.company._id)}
                          >
                            View
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>
                );
              })}
            </Card>
          </div>
        </>
      )
      }
    </>
  );
};

export default Companies;
