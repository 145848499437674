import React from "react";
import { Outlet } from "react-router-dom";
import { baseUrl, privateConfig } from "../../../routes/config";
import SideBar from "../../UI/SideBar/SideBar";
import "./index.css";

const Dashboard = () => {
  return (
    <div className="d-flex justify-content-between">
      <SideBar
        menuData={privateConfig}
        panelName="Superadmin"
        baseUrl={baseUrl}
      />
      <div className="xrg-outlet-main">
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
