import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Dashboard from "../Components/Logical/Dashboard";
import { onSetToken } from "../store/actions/auth";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { privateConfig, publicConfig } from "./config";

const PrivateRouter = () => {
  const { pathname } = useLocation();
  const isLoggedIn = useAppSelector((state) => state.auth.loggedIn);
  const authToken = sessionStorage.getItem("authKey") || false

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isLoggedIn && authToken) {
      dispatch(onSetToken({ token: authToken }));
    }
  }, [isLoggedIn, authToken, dispatch]);

  return (
    <>
      {isLoggedIn && <Dashboard />}
      {!authToken && <Navigate state={{ redirectTo: pathname }} to="/" />}
    </>
  );
};

const MainRoutes = () => {
  return (
    <Routes>
      {publicConfig.map((config) => (
        <Route
          key={config.path}
          path={config.path}
          element={<config.element />}
        />
      ))}
      <Route path="/superadmin" element={<PrivateRouter />}>
        {privateConfig.map((config) => (
          <Route
            key={config.path}
            path={config.path}
            element={<config.element />}
          />
        ))}
      </Route>
      <Route path="*" element={<Navigate to="/superAdmin/login" />} />
    </Routes>
  );
};

export default MainRoutes;
