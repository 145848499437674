const url = {
  login: "/superAdmin/login",
  company: "/superAdmin/getAllCompanies",
  addCompanies: "/superAdmin/addCompany",
  viewCompanies: "/superAdmin/viewCompany/",
  editCompany: "/superAdmin/edit/",
  changePasswordById: "/superAdmin/changePasswordById",
  changeComapanyPasswordById: "/superAdmin/changeComapanyPasswordById",
  toggleBlockUnblock: "/superAdmin/toggleBlockUnblock",
  getAllUsersOfCompany: "/superAdmin/getAllUsersByCompanyId",
  getStats: "/superAdmin/stats",
  getAllExamCategories: "/superAdmin/getExamCategories",
  addExamCategory: "/superAdmin/addExamCategory",
  editExamCategory: "/superAdmin/updateExamCategory",
  deleteExamCategory: "/superAdmin/deleteExamCategory",
  changeUserRole: "/superAdmin/updateUserRole",
  blockUnblockUser: "/superAdmin/blockUnblockUser",
  userUpdate: "/superAdmin/userUpdate",
  register: "/superAdmin/user/register",
  addLanguage: "/superAdmin/addLanguage",
  getTranslatedLanguage: "/superAdmin/getUserById",
  getAllCompanyUsers: "/superAdmin/company/users",
  getLanguage: "/public/getAllLanguage",

  subjects: {
    get: "/subject/getAll",
    updateSubjects: "/subject/getAll",
    getSubjectById: "/subject/getById",
    updateSubject: "/subject/update",
    addSubject: "/subject/insert",
    getTopics: "/subject/topic",
    insertTopic: "/subject/topic/insert",
    updateTopic: "/subject/topic/update",
  }

};

export default url;
