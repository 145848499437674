import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import CardBasic from '../../../Components/Card/Card.Basic'
import { AdminService } from '../../../services/admin.service'
import Cards from '../../../Components/Cards/Cards'

export default function CompanyStats() {

    const [stats, setStats] = useState<any>()

    const getStats = async () => {
        await AdminService.getStats().then((res: any) => {
            setStats(res.data)
        }
        )
    }
    useEffect(() => {
        getStats()
    }, [])

    console.log(stats)
    return (
        <>

            <Row>

                <Col md={4}>
                    <Cards
                        title="Total Questions"
                        amount={stats?.totalQuestions}
                    />
                </Col>
                <Col md={4}>
                    <Cards
                        title="Total Companies"
                        amount={stats?.totalCompanies}
                    />
                </Col>
                <Col md={4}>
                    <Cards
                        title="Total Questions Papers"
                        amount={stats?.totalQuestionPapers}
                    />
                </Col>
                <Col md={4}>
                    <Cards
                        title="Total Subjects"
                        amount={stats?.totalSubjects}
                    />
                </Col>

            </Row>

        </>
    )
}