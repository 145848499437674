import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import { AdminService } from "../../services/admin.service";
import toast from "react-hot-toast";

interface IEditTopicModal {
    show: any,
    handleClose: any,
    data: any
}


export default function EditTopicModal({ show, handleClose, data }: IEditTopicModal) {

    const [topicData, setTopicData] = useState<any>()


    const handleUpdateTopic = async () => {
        const payload = {
            name: topicData.name,
            sub_topics: topicData.sub_topics
        }
        await AdminService.updateTopic(topicData?._id, payload).then((res) => {
            if (res.status === 200) {
                toast.success("Topic Updated")
                handleClose();
            }
        }).catch(err => {
            toast.error(err.response.data)
        })
    }

    const addTopic = () => {
        const oldTopics = topicData.sub_topics || [];
        oldTopics.push({
            name: ""
        });

        const updatedSubject = {
            ...topicData,
            sub_topics: oldTopics
        };

        setTopicData(updatedSubject);
    };


    const deleteTopic = (topicIndex: number) => {
        const oldTopics = [...topicData.sub_topics];
        oldTopics.splice(topicIndex, 1);

        const updatedSubject = {
            ...topicData,
            sub_topics: oldTopics
        };

        setTopicData(updatedSubject);
    };

    const handleTopicChange = (index: number, e: any) => {
        const newTopics = [...topicData.sub_topics];
        newTopics[index].name = e.target.value;
        setTopicData({ ...data, sub_topics: newTopics });
    };



    useEffect(() => {
        setTopicData(data);
    }, [show])

    return (
        <Offcanvas show={show} onHide={handleClose} animation={true} placement="end">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Edit Topic</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="px-4" style={{ height: "84vh", overflowY: "auto", overflowX: "hidden" }}>
                    <Form.Group>
                        <Form.Label>Topic Name</Form.Label>
                        <Form.Control className="form-control" defaultValue={topicData?.name} onChange={(e: any) => setTopicData({ ...topicData, name: e.target.value })} />
                    </Form.Group>
                    <Form.Group className="mt-3">
                        <Form.Label>Sub Topics</Form.Label>
                        <div>
                            {
                                topicData && topicData.sub_topics && topicData.sub_topics.length > 0 && topicData.sub_topics.map((topic: any, index: number) => {
                                    return (<>
                                        <Row className="mb-2 align-items-center">
                                            <Col md={11}>
                                                <Form.Control
                                                    type="text"
                                                    value={topic.name}
                                                    onChange={(e) => handleTopicChange(index, e)}
                                                />
                                            </Col>
                                            <Col md={1}>
                                                <FontAwesomeIcon icon={faTrashAlt} className="text-danger" onClick={() => deleteTopic(index)} />
                                            </Col>
                                        </Row>
                                    </>)
                                })
                            }
                        </div>
                        <div className="d-flex justify-content-end mt-4">
                            <Button size="sm" onClick={addTopic}>
                                Add Sub-topic
                            </Button>
                        </div>
                    </Form.Group>
                </div>
                <div>
                    <Button className="w-100" onClick={handleUpdateTopic}>
                        Save
                    </Button>
                </div>
            </Offcanvas.Body>
        </Offcanvas >
    )
}